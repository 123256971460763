.save-btn-spinner {
display:inline-block;
position:relative;
width:10px;
height:10px;
left:-40px;
top:-5px
}

.login-bg {
height:100vh;
display:flex;
justify-content:space-between;
align-items:center
}

.new-login-btn {
padding:8px 35px;
font-size:15px;
line-height:19px;
font-weight:700;
border-radius:2px;
border:2px solid transparent;
transition:.4s all ease
}

.add-d-flex {
display:flex
}

.add-d-flex button {
margin-right:1rem
}

.new-login-btn:hover {
background:#0078d4;
border:2px solid #0078d4;
color:#fff!important;
transition:.4s all ease
}

.login-left-side-wrapper {
flex-grow:1;
background-position:left center;
background-repeat:no-repeat;
background-size:cover;
height:inherit;
transition:.4s all ease-in-out
}

.signup-grow-change {
width:480px;
background-position:35% center;
flex-grow:unset;
transition:.4s all ease-in-out
}

.signup-width {
flex-grow:1;
width:auto!important;
transition:.4s all ease-in-out
}

.login-left-side {
display:flex;
justify-content:space-between;
flex-direction:column;
position:relative;
height:inherit
}

.login-left-side-wrapper .top-area {
padding:34px 42px;
height:inherit;
position:absolute;
width:100%
}

.login-left-side-wrapper .top-area .logo {
cursor:pointer;
width:fit-content
}

.login-left-side-wrapper .bottom-area {
background:transparent linear-gradient(180deg,#00000000 0%,#141516 100%);
background-position:center center;
background-repeat:no-repeat;
background-size:cover;
height:371px;
position:absolute;
width:100%;
bottom:0
}

.login-left-side-wrapper .bottom-area .quotes-wrapper {
position:absolute;
bottom:0;
padding:48px 36px
}

.login-left-side-wrapper .bottom-area .quotes-wrapper .quote {
font-family:"Roboto",sans-serif;
font-weight:300;
font-style:italic;
font-size:17px;
line-height:1.5;
color:#C5C5C5;
margin-bottom:0
}

.login-left-side-wrapper .bottom-area .quotes-wrapper .author {
margin-top:28px;
font-family:"Roboto",sans-serif;
font-weight:400;
font-size:15px;
line-height:1.5;
letter-spacing:1.5px;
color:#FFF;
text-transform:uppercase;
margin-bottom:0
}

.login-form {
padding:31px 41px;
width:700px;
height:inherit;
transition:ease-in-out .3s;
background:#fff;
display:flex;
flex-direction:column;
transition:.3s all ease-in-out
}

.login-form .other-page-wrapper {
width:430px;
margin:auto;
flex-grow:1;
display:flex;
align-items:center;
flex-direction:column;
justify-content:center;
gap:12px
}

.login-form .other-page-wrapper .img-wrapper {
width:136px;
height:136px;
border-radius:50%;
background-color:#e5f2fb;
display:flex;
align-items:center;
justify-content:center;
margin:15px 0
}

.login-form .other-page-wrapper h1 {
font-family:"Roboto",sans-serif;
font-weight:500;
font-size:34px;
line-height:1.5;
color:#1D1F20;
margin-bottom:0;
text-align:center
}

.login-form .other-page-wrapper p {
font-family:"Roboto",sans-serif;
font-weight:400;
font-size:16px;
line-height:1.5;
color:#7F7F7F;
margin-bottom:0;
text-align:center
}

.login-form .form-area-wrapper {
width:430px;
margin:auto;
flex-grow:1;
display:flex;
align-items:center;
gap:18px;
flex-direction:column;
justify-content:center
}

.login-form .form-area-wrapper h1.title {
font-family:"Roboto",sans-serif;
font-weight:500;
font-size:34px;
line-height:1.5;
color:#1D1F20;
margin-bottom:0;
text-align:center
}

.login-form .form-area-wrapper.vendor-signup-form .sign-up-wrapper,.login-form .form-area-wrapper .sign-up-wrapper,.login-form .other-page-wrapper form,.login-form .form-area-wrapper form {
width:100%
}

.ai-text-tabs-algin,ul.ai-textbox-features,.ai-product-text-tabs-algin {
display:flex;
flex-wrap:wrap;
padding-left:0;
margin-bottom:0;
list-style:none;
align-items:center
}

ul.ai-textbox-features li:first-child span {
margin-right:11px
}

ul.ai-textbox-features li span {
width:40px;
height:40px;
display:flex;
justify-content:center;
align-items:center;
border-radius:50%;
border:1px solid #d8d8d8;
transition:.4s all ease
}

ul.ai-textbox-features li span:hover {
background:#e9eaea;
border:1px solid #e9e9e9;
cursor:pointer
}

.ai-login-form-group {
display:flex;
flex-direction:column;
width:100%
}

.ai-login-form-group label {
color:#1D1F20;
font-family:Roboto;
font-style:normal;
font-weight:500;
font-size:14px;
line-height:2.5
}

.ai-login-form-group input.ai-textbox {
height:calc(1.5em + 0.75rem + 7px);
border:1px solid #C0C8CE;
border-radius:3px;
color:#1D1F20;
font-size:15px;
line-height:24px;
font-weight:500;
padding:10px
}

.ai-login-form-group input.ai-textbox:focus {
height:calc(1.5em + 0.75rem + 7px);
border:2px solid #0078d4;
color:#172b4d;
background-color:#fff;
outline:0;
box-shadow:none
}

.pwd-visibility-btn {
position:absolute;
right:0;
top:47px;
padding:5px 10px;
cursor:pointer
}

.pwd-visibility-btn img {
margin-bottom:0
}

.ai-login-form-group,.ai-contact-form-group {
margin-bottom:16px;
position:relative
}

.ai-login-submit-btn-base {
width:100%
}

.ai-login-submit-btn {
font-family:Roboto;
font-style:normal;
color:#fff;
font-size:15px;
line-height:1.4;
font-weight:500;
padding:9.5px 30px;
background-color:#0078D4;
border-radius:2px;
border:none;
width:100%
}

.ai-mt-2 {
margin-top:2rem
}
.save-btn-spinner {
display:inline-block;
position:relative;
width:10px;
height:10px;
left:-40px;
top:-5px
}

.save-btn-spinner div {
transform-origin:40px 11px;
animation:save-btn-spinner 1.2s linear infinite
}

.save-btn-spinner div:after {
content:" ";
display:block;
position:absolute;
top:3px;
left:37px;
width:2px;
height:4px;
border-radius:45%;
background:#fff
}

.save-btn-spinner div:nth-child(1) {
transform:rotate(0deg);
animation-delay:-1.1s
}

.save-btn-spinner div:nth-child(2) {
transform:rotate(30deg);
animation-delay:-1s
}

.save-btn-spinner div:nth-child(3) {
transform:rotate(60deg);
animation-delay:-.9s
}

.save-btn-spinner div:nth-child(4) {
transform:rotate(90deg);
animation-delay:-.8s
}

.save-btn-spinner div:nth-child(5) {
transform:rotate(120deg);
animation-delay:-.7s
}

.save-btn-spinner div:nth-child(6) {
transform:rotate(150deg);
animation-delay:-.6s
}

.save-btn-spinner div:nth-child(7) {
transform:rotate(180deg);
animation-delay:-.5s
}

.save-btn-spinner div:nth-child(8) {
transform:rotate(210deg);
animation-delay:-.4s
}

.save-btn-spinner div:nth-child(9) {
transform:rotate(240deg);
animation-delay:-.3s
}

.save-btn-spinner div:nth-child(10) {
transform:rotate(270deg);
animation-delay:-.2s
}

.save-btn-spinner div:nth-child(11) {
transform:rotate(300deg);
animation-delay:-.1s
}

.save-btn-spinner div:nth-child(12) {
transform:rotate(330deg);
animation-delay:0s
}

@keyframes save-btn-spinner {
0% {
opacity:1
}

100% {
opacity:0
}
}

.animation-logo {
position:fixed;
background:#ffffffd1;
top:0;
bottom:0;
left:0;
right:0;
z-index:1080;
display:flex;
align-items:center;
justify-content:center
}

.svg-logo {
width:81px;
height:61px;
background-image:url(../loaders/gradient-img.jpg);
-webkit-mask:url(../loaders/svg-logo-animation.svg) center/cover;
mask:url(../loaders/svg-logo-animation.svg) center/cover;
-webkit-background-clip:content-box;
background-clip:content-box;
background-size:200% 200%;
animation:gradient-animation 5s ease-in-out infinite
}

@keyframes gradient-animation {
0% {
background-position:0 100%
}

50% {
background-position:180% 0
}

100% {
background-position:0 150%
}
}

* {
margin:0;
padding:0;
box-sizing:border-box
}

.header {
width:100%;
background-color:#ffffff;
height:58px;
display:grid;
grid-template-columns:40% 20% 40%;
flex-wrap:nowrap;
box-shadow:0 1px 5px rgba(0,0,0,0.1);
border-bottom:1px solid #DFDFDF
}

.header .header-section1 {
padding-left:30px;
display:flex;
align-items:center;
flex-wrap:nowrap
}

.header-logo {
height:60%;
display:flex;
align-items:center;
cursor:pointer
}

.menu-button {
margin-left:20px;
padding:0 20px;
height:60%;
display:flex;
align-items:center;
white-space:nowrap;
gap:5px;
border-right:1px solid #e0e0e0;
color:#5F6368
}

.menu-but {
cursor:pointer;
padding:5px 15px;
border-radius:5px;
transition:ease .3s all
}

.menu-but:hover {
background-color:#E8F0FE;
color:#0078D4;
transition:ease .3s all;
cursor:pointer
}

.menu-active {
cursor:pointer;
padding:5px 15px;
border-radius:5px;
background-color:#E8F0FE;
color:#0078D4;
transition:ease .3s all;
cursor:pointer
}

.header-undo-redo-logo {
height:60%;
display:flex;
align-items:center;
justify-content:space-between;
padding:0 15px;
margin-left:10px
}

.header-undo-redo-logo img {
cursor:pointer;
width:16px
}

.header-undo-redo-logo span {
cursor:pointer;
padding:5px 15px;
border-radius:5px;
transition:ease .3s all
}

.header-undo-redo-logo span:hover {
background-color:#E8F0FE;
color:#0078D4;
transition:ease .3s all;
cursor:pointer
}

.header .header-section2 {
display:flex;
align-items:center;
justify-content:center;
gap:10px;
white-space:nowrap;
flex-wrap:nowrap
}

.header .header-section2 .File-name {
position:relative;
min-width:20px;
max-width:60%;
padding:5px 10px;
overflow:hidden;
text-overflow:ellipsis;
white-space:nowrap;
color:#222222;
text-align:left;
font:Roboto;
font-weight:700;
letter-spacing:0
}

.header-section2>.File-name:focus {
text-overflow:clip
}

.header-section2>.File-name:not(:focus) {
text-overflow:ellipsis
}

.header-section2>.File-name:hover {
transition:.2s all ease;
text-overflow:clip
}

.header-section2 .File-name[placeholder]:empty:before {
content:attr(placeholder);
color:#555
}

.header>div:nth-child(3) {
display:flex;
align-items:center;
flex-wrap:nowrap;
justify-content:flex-end;
gap:15px;
padding:0 15px
}

.header-section3 button {
background-color:#0078d4;
color:#ffffff;
text-transform:capitalize;
box-shadow:none!important;
transition:ease .3s all;
border:none
}

.listen-button {
border:none;
background-color:transparent
}

.download-btn {
align-items:center;
background:#0078d4!important;
border-radius:3px!important;
color:#fff!important;
display:flex;
font-family:"Roboto"!important;
font-size:16px!important;
font-weight:400;
gap:7px;
letter-spacing:0;
opacity:1!important;
padding:8px 15px!important;
text-align:center
}

.download-btn:hover {
cursor:pointer
}

.header-section3>span {
width:36px;
height:36px;
border-radius:50%;
background-color:#656565
}

body {
overflow:hidden
}

.container1 {
position:fixed;
width:100%!important;
height:100%!important
}

.ailaysa-canvas-editor-main-wrapper {
display:flex;
height:93.5%;
overflow:hidden;
background-color:#ffffff
}

.ailaysa-canvas-sidebar-col-wrapper {
width:auto;
max-width:100%;
height:100%;
position:sticky;
display:flex;
background-color:#ffffff;
/* border-right:1px solid #DFDFDF; */
position:relative;
z-index: 2;
}

.ailaysa-canvas-working-col-wrapper {
flex-grow:1;
overflow:hidden;
width:100%;
height:100%;
position:relative;
/* background-color:#e6eff7 */
}

.canvas-editor-space {
position:absolute;
top:0;
overflow:hidden;
width:100%;
height:100%
}

.canvas-space {
box-shadow:0 1px 4px #0000001A;
position: relative;
}

.side-bar-item {
display:flex;
align-items:center;
flex-direction:column;
gap:10px;
transition:all .3s ease
}

.side-bar-heading {
text-align:center;
font-size:12px;
font-weight:400;
font-family:'Roboto';
letter-spacing:0;
color:#5E6368;
opacity:1
}

.react-tabs {
display:flex;
width:auto;
color:#5E6368;
background:#ffffff
}

.react-tabs__tab-list {
display:flex;
flex-direction:column;
width:100px;
margin:0;
padding:0;
color:#5E6368;
background:#ffffff
}

.react-tabs__tab {
height:70px;
list-style:none;
padding:12px 6px;
cursor:pointer;
color:#5E6368
}

.react-tabs__tab--selected {
background:#FFF;
outline:none;
color:#5E6368;
border-right:none!important
}

.react-tabs__tab-panel {
display:none;
width:350px
}

.display-tab-pannel {
display:block
}

.react-tabs__tab-panel--selected {
display:block;
background:#FFF;
border-bottom:1px solid #DFDFDF;
/* border-top:1px solid #DFDFDF */
}

.react-tabs__tab--selected {
border-right:none;
/* border-top:1px solid #DFDFDF; */
background-color:#e6eaef
}

.panel-content {
text-align:center;
border-left:1px solid #DFDFDF!important
}

.sidebar-content {
display:flex;
align-items:center;
justify-content:center;
gap:14px;
flex-wrap:wrap;
padding:10px;
position:relative;
}

.sidebar-tabs-heading {
display:flex;
justify-content:space-between;
align-items:center;
width:100%
}

.panel-content-resize {
background-color:#FFF!important;
border-left:1px solid #DFDFDF!important
}

.sidebar-search-resize {
display:flex;
align-items:center;
padding:12px;
gap:10px;
background:#FFF;
border-radius:4px;
opacity:1;
width:100%
}

.sidebar-search {
display:flex;
align-items:center;
/* padding:12px; */
gap:10px;
background:#ffffff;
border-radius:4px;
opacity:1;
width:100%
}

.search-icon {
color:#777B7E;
opacity:1
}

.close-icon {
color:#5F6368;
opacity:1;
border-radius:50%;
transition:ease .3s all;
padding:3px
}

.close-icon:hover {
background-color:#E8F0FE;
color:#0078D4!important;
transition:ease .3s all;
cursor:pointer
}

.grid {
display:grid;
grid-template-columns:auto auto;
position:relative;
gap:10px;
place-items:left;
padding:8px;
min-height:50%;
max-width:100%;
overflow-y:scroll;
scrollbar-width:thin;
-ms-overflow-style:none;
grid-auto-rows:1fr;
overflow-x:hidden
}

.grid-template::-webkit-scrollbar-track,.api-photos::-webkit-scrollbar-track,.canvas-editor-space::-webkit-scrollbar-track,.text-grid::-webkit-scrollbar-track,.pharaphrasing-container::-webkit-scrollbar-track,.ts-draft-editor::-webkit-scrollbar-track,.layer-container::-webkit-scrollbar-track,.adjust-div::-webkit-scrollbar-track,.page-bar-grid::-webkit-scrollbar-track,.ailaysa-canvas-textarea::-webkit-scrollbar-track,.workspace-input-itrate::-webkit-scrollbar-track,.grid-inpaint-::-webkit-scrollbar-track,.grid-design-tab::-webkit-scrollbar-track
.scrollContainer::-webkit-scrollbar-track,.sizemenu::-webkit-scrollbar-track,.size-container::-webkit-scrollbar-track,.grid::-webkit-scrollbar-track {
padding:0 5px;
background:#fff;
z-index:3
}

.grid-template::-webkit-scrollbar,.api-photos::-webkit-scrollbar,.canvas-editor-space::-webkit-scrollbar,.text-grid::-webkit-scrollbar,.pharaphrasing-container::-webkit-scrollbar,.sizemenu::-webkit-scrollbar,.ts-draft-editor::-webkit-scrollbar,.layer-container::-webkit-scrollbar,.adjust-div::-webkit-scrollbar,.page-bar-grid::-webkit-scrollbar,.ailaysa-canvas-textarea::-webkit-scrollbar,.workspace-input-itrate::-webkit-scrollbar,.grid-inpaint::-webkit-scrollbar,.grid-design-tab::-webkit-scrollbar,.scrollContainer::-webkit-scrollbar,.size-container::-webkit-scrollbar,.grid::-webkit-scrollbar {
width:8px!important;
z-index:3
}

.grid-template::-webkit-scrollbar-thumb,.api-photos::-webkit-scrollbar-thumb,.canvas-editor-space::-webkit-scrollbar-thumb,.text-grid::-webkit-scrollbar-thumb,.pharaphrasing-container::-webkit-scrollbar-thumb,.sizemenu::-webkit-scrollbar-thumb,.ts-draft-editor::-webkit-scrollbar-thumb,.layer-container::-webkit-scrollbar-thumb,.adjust-div::-webkit-scrollbar-thumb,.page-bar-grid::-webkit-scrollbar-thumb,.ailaysa-canvas-textarea::-webkit-scrollbar-thumb,.workspace-input-itrate::-webkit-scrollbar-thumb,.grid-inpaint::-webkit-scrollbar-thumb,.grid-design-tab::-webkit-scrollbar-thumb,.scrollContainer::-webkit-scrollbar-thumb,.size-container::-webkit-scrollbar-thumb,.grid::-webkit-scrollbar-thumb {
background-color:#c3c4c6;
border-radius:50px;
border:2px solid #fff;
z-index:3
}

.ailaysa-canvas-working-col-wrapper>.toolbars {
width:100%;
z-index:2;
height:42px;
top:20px;
padding:0 20px;
position:absolute;
display:grid;
align-items:center
}

.alltoolbars {
width:100%;
border-radius:5px;
height:100%;
display:flex;
align-items:center;
justify-content:space-between;
white-space:nowrap
}

.image-toolbar-icons {
height:inherit!important;
outline:none!important;
background-color:#ffffff;
transition:ease .3s all;
color:#5F6368;
border-radius:4px;
margin:0 15px;
font-size:13px;
font-weight:600;
font-family:'Roboto';
display: flex;
align-items: center;
}

.separator {
background:#DFDFDF;
width:1px;
height:60%
}

.crop-container {
display:flex;
align-items:center
}

.image-toolbar-icons-active {
height:inherit!important;
outline:none!important;
transition:ease .3s all;
border-radius:4px;
margin:0 15px;
background-color:#E8F0FE;
color:#0078D4!important;
font-size:13px;
font-weight:600;
font-family:'Roboto'
}

.image-toolbar-icons:hover {
background-color:#E8F0FE;
color:#0078D4!important;
transition:ease .3s all;
cursor:pointer
}

.adjust-div {
height:250px;
width:200px;
padding-right:10px;
overflow-y:scroll;
overflow-x:hidden;
overflow-y:scroll;
scrollbar-width:thin;
-ms-overflow-style:none
}

.css-3bmhjh-MuiPaper-root-MuiPopover-paper {
box-shadow:0 1px 4px #0000001A!important;
border:1px solid #DFDFDF!important
}

.filter-div-wrapper,.adjust-div-wrapper {
padding:10px 5px 10px 10px
}

.filter-div {
display:grid;
grid-template-columns:auto auto auto;
gap:5px
}

.filter-gride-items {
width:100px;
height:100px;
padding:5px;
cursor:pointer;
border:2px solid #ccc;
border-radius:5px;
position:relative;
transition:.3s ease all!important
}

.filter-gride-items:hover {
cursor:pointer;
background:#F8FAFC!important;
opacity:1!important;
transition:.3s ease all!important
}

.filter-gride-items:hover .image-filter-samp {
opacity:.3;
transition:.3s ease all!important
}

.filter-gride-items:hover .middle {
opacity:1;
transition:.3s ease all!important
}

.middle {
transition:.3s ease all!important;
opacity:0;
position:absolute;
top:50%;
left:50%;
transform:translate(-50%,-50%);
-ms-transform:translate(-50%,-50%);
text-align:center
}

.overlay-text {
font-size:12px;
font-family:'Roboto';
font-weight:700;
font-style:normal
}

.filter-gride-items:active {
background:#E7ECEC!important;
opacity:1!important;
transition:.3s ease all!important
}

.image-filter-samp {
width:100%;
padding:8px
}

.adjust-headers {
text-align:center;
font-weight:700;
font-style:medium;
font-size:13px!important;
font-family:'Roboto';
letter-spacing:0;
margin:0!important
}

.adjust-range-container {
display:flex;
align-items:center;
gap:10px;
margin-bottom:10px
}

.slider-range {
width:75%!important
}

.input-value {
width:25%!important;
font-weight:700;
font-style:medium;
font-size:13px!important;
font-family:'Roboto';
text-align:center;
border-color:#0074D3!important;
outline-color:#0074D3!important
}

.font-family {
width:fit-content;
z-index:1;
height:46px!important;
overflow:hidden;
outline:none!important;
border:none!important;
display:flex;
align-items:center;
padding:0 10px;
border-radius:5px;
background-color:#ffffff;
box-shadow:0 1px 4px #0000001a;
cursor:pointer;
color:#5F6368
}

.spacecontainer>span {
padding:2px!important
}

.spacecontainer>span>svg {
height:38px!important
}

.textmore-icon {
height:38px
}

.font-size {
z-index:1;
width:-moz-fit-content;
width:fit-content;
white-space:nowrap;
height:46px;
background-color:#ffffff;
display:flex;
gap:10px;
border-radius:5px;
padding:5px 10px;
box-shadow:0 1px 4px #0000001A
}

.font-size span {
color:#5F6368!important;
padding:2px;
border-radius:4px
}

.font-size span:nth-child(2) {
padding:5px 0;
transition:ease .3s all
}

.font-size>span:nth-child(2n+1):hover {
background-color:#E8F0FE;
color:#0078D4!important;
transition:ease .3s all;
cursor:pointer
}

.font-size span:nth-child(2) {
display:flex;
gap:15px;
transition:ease .3s all
}

.text-styles {
width:fit-content;
white-space:nowrap;
height:46px!important;
background-color:#ffffff;
display:flex;
gap:10px;
border-radius:5px;
padding:5px 10px;
box-shadow:0 1px 4px #0000001A;
z-index:1
}

.active {
background-color:#E8F0FE;
color:#0078D4!important;
transition:ease .3s all;
cursor:pointer
}

.text-styles span {
color:#5F6368!important;
padding:2px;
border-radius:4px;
display:flex;
align-items:center;
transition:ease .3s all
}

.toolbar-icons {
height:100%!important
}

.text-styles span:hover {
background-color:#E8F0FE;
color:#0078D4!important;
transition:ease .3s all;
cursor:pointer
}

.more-tools {
position:absolute;
top:10px;
right:10px;
z-index:1;
width:fit-content;
white-space:nowrap;
height:46px;
display:flex;
gap:10px;
border-radius:5px;
padding:5px 10px;
background-color:#ffffff;
box-shadow:0 1px 4px #0000001A;
transition:ease .3s all
}

.more-tools span {
border-radius:4px;
padding:2px;
color:#5F6368;
cursor:pointer
}

.more-tools span:hover {
background-color:#E8F0FE;
color:#0078D4!important;
transition:ease .3s all;
cursor:pointer
}

.images-img {
width:100%!important;
height:100%;
cursor:pointer;
border-radius:10px;
box-shadow:0 1px 4px #0000001a
}

.grid-upload {
max-height:81vh
}

.upload-div {
width:100%
}

.upload {
width:100%;
padding:12px;
background:#0078D4!important;
border-radius:3px!important;
opacity:1!important;
padding:8px 15px!important;
color:#FFF!important;
text-align:center;
font-weight:400;
font-style:medium;
font-size:16px!important;
font-family:'Roboto';
letter-spacing:0;
border:none
}

.grid-upload-height {
height:76vh
}

.grid-ai-history-height{
    height: 82vh;
}

.grid-item-uploads2 {
display:flex;
align-items:center;
justify-content:space-between;
padding:10px;
border:1px solid #dfdfdf;
border-radius:4px
}

.grid-item {
cursor:pointer;
background-color:#dcdcdc85;
text-align:center;
padding:5px;
font-size:30px
}

.remove-icon {
padding:5px;
background:#FFF;
border-radius:50%;
box-shadow:0 4px 10px #33333340;
color:#000;
position:absolute;
transition:all .3s ease;
top:0;
right:0
}

.add-text {
width:100%
}

.add-heading1 {
cursor:pointer;
font-weight:500;
text-transform:none;
font-family:'Roboto';
font-size:28px;
letter-spacing:0;
text-align:start;
padding:8px 16px;
background:#e9ebee;
border-radius:4px;
opacity:1;
margin-bottom:10px
}

.add-heading2 {
cursor:pointer;
font-weight:500;
text-transform:none;
font-family:'Roboto';
font-size:24px;
letter-spacing:0;
text-align:start;
padding:8px 16px;
background:#FFF;
border-radius:4px;
opacity:1;
margin-bottom:10px
}

.add-heading3 {
cursor:pointer;
font-weight:500;
font-family:'Roboto';
font-style:bold;
text-transform:none;
font-size:16px;
letter-spacing:0;
text-align:start;
padding:8px 16px;
background:#FFF;
border-radius:4px;
opacity:1
}

.add-heading1:hover,.add-heading2:hover,.add-heading3:hover {
background:rgba(0,120,212,0.1);
opacity:1
}

.add-heading1:active,.add-heading2:active,.add-heading3:active {
background:#E7ECEC;
opacity:1
}

.text-grid {
height:72vh;
overflow:auto;
display:flex;
gap:5px
}

.text-grid img {
width:100%;
/* background-color:#fff;
box-shadow:0 1px 4px #0000001a;
object-fit:contain!important */
}

.grid-photos {
height:81vh;
padding-right:5px
}

.api-photos {
display:flex;
width:100%;
gap:5px;
overflow:auto;
overflow-x:hidden;
}

.api-photos .odd-photos {
width:100%;
display:flex;
flex-direction:column;
gap:5px
}

.api-photos.api-bg-photos {
height:75vh
}

.api-photos .even-photos {
width:100%;
display:flex;
flex-direction:column;
gap:5px
}

.api-photos img {
/* width:inherit */
}

.searcher {
width:100%;
padding:5px;
height:100%;
border:none;
outline:none;
font-family:'Roboto';
color:#74818D;
font-weight:400;
font-size:14px;
background-color:inherit
}

.seacrh-icon {
cursor:pointer
}

.sidebar-background-color {
display:flex;
align-items:center;
flex-direction:row;
width:100%;
gap:5px;
box-shadow:0 1px 4px #0000001A;
padding:5px
}

.bg-colors {
cursor:pointer;
border-radius:4px;
width:10%;
height:30px;
transition:all .3s ease
}

.add-colors {
cursor:pointer;
border-radius:4px;
width:10%;
height:30px;
transition:all .3s ease
}

.add-colors:hover {
color:#000;
transition:all .3s ease
}

.bg-colors:hover {
transform:scale(1.2);
transition:all .3s ease
}

.bgeye {
cursor:pointer;
border-radius:4px;
width:10%;
height:30px;
color:#5E6368;
background-color:transparent;
transition:all .3s ease
}

.bgeye:hover {
color:#000;
transition:all .3s ease
}

.eye-icon {
width:100%!important;
height:100%!important
}

.add-icon {
height:100%!important;
width:100%!important
}

.grid-background {
height:76vh
}

.colorPicker {
width:100%!important;
border:none!important;
z-index:2
}

.colorPicker>div:nth-child(1) {
display:none!important
}

.sizedropdown>div:nth-child(3) {
height:350px;
-ms-overflow-style:none!important;
scrollbar-width:none!important
}

.sizedropdown>div:nth-child(3)::-webkit-scrollbar {
display:none!important;
overflow-y:hidden!important
}

.sizedropdown>div:nth-child(3) ul li {
display:flex;
align-items:center;
justify-content:center
}

.grid-resize {
width:100%!important;
display:flex;
align-items:flex-start;
flex-direction:column;
position:relative;
gap:10px;
place-items:left;
padding:8px
}

.resize-custom-input-box {
display:grid;
width:100%;
gap:10px;
grid-template-columns:auto auto;
justify-content:space-between!important;
align-items:center;
padding-bottom:15px;
border-bottom:1px solid #DFDFDF
}

.custom-size-category {
font-family:'Roboto';
font-size:14px;
font-weight:400;
font-style:normal
}

.size-container {
width:100%;
overflow-y:scroll;
scrollbar-width:thin;
-ms-overflow-style:none;
overflow-x:hidden;
height:60vh
}

.size-type {
display:flex;
align-items:center;
justify-content:space-between;
padding:10px;
font-size:14px;
font-family:'Roboto';
font-weight:400;
border-radius:4px;
margin-bottom:5px
}

.size-type-ff {
    display:flex;
    align-items:center;
    justify-content:left;
    padding:10px;
    /* margin-bottom: 20px; */
   
    }
    

.size-type-active {
padding:10px;
font-size:14px;
font-family:'Roboto';
font-weight:400;
border-radius:4px;
cursor:pointer;
background:#E7ECEC;
opacity:1;
transition:all .3s ease;
pointer-events:none
}

.size-on-hover {
display:none;
font-size:12px;
font-family:'Roboto';
font-weight:400
}

.size-type:hover .size-on-hover {
display:block;
font-weight:700
}

.size-type-ff:hover .size-on-hover {
    display:block;
    font-weight:700
    }

.size-type:hover {
cursor:pointer;
background:rgba(0,120,212,0.1);
opacity:1;
transition:all .3s ease
}

.size-type-ff:hover {
    cursor:pointer;
    background:rgba(0,120,212,0.1);
    opacity:1;
    transition:all .3s ease
    }

.size-type:active {
cursor:pointer;
background:#E7ECEC;
opacity:1;
transition:all .3s ease
}

.size-type-ff:active {
    cursor:pointer;
    background:#E7ECEC;
    opacity:1;
    transition:all .3s ease
    }

.file-container {
padding:10px
}

.file-list {
padding:5px;
font-size:16px;
font-family:'Roboto';
font-weight:400;
font-style:normal;
transition:all .3s ease;
border-radius:4px
}

.file-list:hover {
cursor:pointer;
background:#F8FAFC;
opacity:1;
transition:all .3s ease
}

.file-list:active {
cursor:pointer;
background:#E7ECEC;
opacity:1;
transition:all .3s ease
}

.fontFamily-container {
width:100%;
/* overflow-y:scroll;
scrollbar-width:thin;
-ms-overflow-style:none;
overflow-x:hidden;
height:76vh */
}

.scrollContainer{
    overflow-x: hidden;
}

.sketchmenu>div:nth-child(3) ul {
padding:0!important
}

.spanOpacity {
width:25px;
margin-left:10px
}

.grid-inpaint {
display:flex;
flex-direction:column;
gap:25px;
width:100%;
overflow-y:auto;
scrollbar-width:thin;
-ms-overflow-style:none;
grid-auto-rows:1fr;
overflow-x:hidden
}

.grid-design-tab {
display:flex;
flex-direction:row;
gap:8px;
width:100%;
overflow-y:auto;
scrollbar-width:thin;
-ms-overflow-style:none;
grid-auto-rows:1fr;
overflow-x:hidden
}

.grid-inpaint-height {
height:74vh
}

.switch-style {
width:auto!important
}

.switch-container {
width:100%
}

.switch {
justify-content:center!important
}

.inpaint-images {
width:40%;
height:100%;
box-shadow:0 1px 4px #0000001a
}

.grid-Inpaint-upload {
height:auto;
width:100%;
border-radius:4px;
position:relative;
transition:all .3s ease
}

.grid-Inpaint-upload:hover .delete-icon {
display:block!important;
transition:all .3s ease
}

.upoad-image-details {
display:flex;
gap:10px;
width:100%;
border-radius:4px;
cursor:pointer;
padding:15px;
transition:all .3s ease
}



.upoad-image-details:hover {
background-color:rgba(0,120,212,0.1);
transition:all .3s ease
}

.upoad-image-details-active {
display:flex;
gap:10px;
width:100%;
cursor:pointer;
padding:15px;
transition:all .3s ease;
background-color:rgba(0,120,212,0.1);
pointer-events:none
}

.design-thumb-image-details {
  display:flex;
  gap:10px;
  width:100%;
  border-radius:4px;
  cursor:pointer;
  transition:all .3s ease;
  margin-bottom: 8px;

  }
  
  
  
  .design-thumb-image-details:hover {
  background-color:rgba(0,120,212,0.1);
  transition:all .3s ease
  }
  
  .design-thumb-image-details-active {
  display:flex;
  gap:10px;
  width:100%;
  margin-bottom: 8px;
  padding: 5px;
  cursor:pointer;
  transition:all .3s ease;
  background-color:rgba(0,120,212,0.1);
  pointer-events:none
  }

.image-details {
display:flex;
align-items:center;
flex-direction:column;
gap:5px;
width:60%;
border-left:1px solid #DFDFDF;
padding-left:10px
}

.details {
font-family:'Roboto';
text-align:start;
width:100%;
display:flex;
align-items:start;
gap:15px
}

.details-but {
font-family:'Roboto';
text-align:start;
width:100%;
display:flex;
align-items:start;
gap:15px;
background:transparent;
color:#0078D4;
cursor:pointer;
border:none;
display:flex;
align-items:center
}

.details-but:hover {
color:#0078D4;
font-weight:bolder
}

.heading-image {
font-size:13px;
font-weight:700;
width:20%
}

.name-image {
white-space:normal;
font-size:12px;
width:80%
}

.img-toolbar {
height:46px;
width:fit-content;
position:absolute;
top:10px;
left:10px;
border-radius:5px;
background-color:#ffffff;
box-shadow:0 1px 4px #0000001a;
z-index:1;
transition: ease .3s all;

}

.delete-container {
display:flex;
align-items:center;
justify-content:space-between;
width:100%
}

.img-toolbar>div {
display:flex;
align-items:center;
height:100%;
gap:10px;
padding:0 5px
}

.img-toolbar>div span {
padding:0 5px;
display:flex;
align-items:center;
font-weight:600;
transition:ease .3s all
}

.img-toolbar>div span:hover {
background-color:#E8F0FE;
color:#0078D4!important;
transition:ease .3s all;
cursor:pointer
}

.toolbardiv {
border-radius:5px;
background-color:#ffffff;
padding:0 10px;
height:100%;
display:flex;
align-items:center
}

.inpainttoolbar {
box-shadow:0 5px 10px rgba(0,0,0,0.1);
position:absolute;
z-index:2;
top:10px;
left:10px
}

.toolbardiv>span {
padding:0 5px;
display:flex;
align-items:center;
font-weight:600;
transition:ease .3s all
}

.toolbardiv>span:hover {
background-color:#E8F0FE;
color:#0078D4!important;
transition:ease .3s all;
cursor:pointer
}

.delete-icon {

padding:4px;

display:none!important
}

.delete-icon:hover {
/* padding:3px; */
color:#000000;
background-color:#ffffff;
/* border-radius:50%; */
cursor:pointer
}

.elementplacement {
display:grid;
grid-template-columns:50% 50%
}

.footer {
z-index:2;
bottom:20px;
right:20px;
position:absolute
}

.zoom-container {
display:flex;
justify-content:end;
gap:12px
}

.translate {
background:#0078D4 0 0 no-repeat padding-box!important;
box-shadow:0 1px 4px #0000001A!important;
border-radius:4px!important;
opacity:1;
padding:8px 14px!important
}

.translate-icon {
color:#F1F1F1;
opacity:1
}

.translate-text {
text-align:center;
font-size:18px;
font-style:medium;
font-weight:400;
font-family:"Roboto";
letter-spacing:0;
color:#FFF;
opacity:1
}

.slider {
width:200px
}

.zoom {
background:#FFF 0 0 no-repeat padding-box;
box-shadow:0 1px 4px #0000001A;
border-radius:4px;
opacity:1;
display:flex;
align-items:center;
gap:12px;
padding:8px 20px
}

.post-editing-sidebar {
width:530px;
background:#FFF;
border:1px solid #E1E5E6;
padding:10px
}

.source-target-lang {
background:#FFF;
border-radius:2px;
opacity:1;
text-align:left;
font-size:14px;
font-weight:700;
font-style:normal;
font-family:'Roboto';
letter-spacing:0;
color:#0078D4;
opacity:1;
white-space:nowrap;
padding:2px 5px
}

.arrow-right-icon {
color:#0078D4
}

.posteditor-icons {
color:#5F6368;
font-size:1.2rem!important;
opacity:1;
cursor:pointer;
width:30px!important;
height:30px!important;
padding:5px!important;
border-radius:50%!important;
background-color:#FFF
}

.posteditor-icon-container {
display:flex;
align-items:center;
gap:15px
}

.posteditor-language-container {
display:flex;
align-items:center
}

.posteditor-header-container {
display:flex;
align-items:center;
justify-content:space-between;
gap:25px
}

.drag-indicator-post-edit {
width:100%;
justify-content:end;
display:inline-flex;
color:#5F6368
}

.page-sidebar {
width:100px;
border:1px solid #DFDFDF
}

.open-close {
position:absolute;
background:#FFF;
cursor:pointer;
z-index:3
}

.open-close-workspace{
position:absolute;
background:#FFF;
cursor:pointer;
z-index:3 
}

.open-close-secondary::after {
content:"";
position:absolute;
background-color:#FFF;
top:-16px;
right:8px;
width:74%;
height:76%;
transform:rotate(-54deg);
border-right:1px solid #DFDFDF;
z-index:2
}

.open-close-secondary::before {
content:"";
position:absolute;
background-color:#FFF;
bottom:-16px;
right:8px;
width:74%;
height:76%;
transform:rotate(54deg);
border-right:1px solid #DFDFDF;
z-index:2
}

.open-close-secondary {
position:absolute;
bottom:50%;
background:#FFF;
right:-24px;
padding:5px 0;
cursor:pointer;
z-index:2;
border-right:1px solid #DFDFDF;
border-top:1px solid #DFDFDF;
border-bottom:1px solid #DFDFDF
}

.page-data-head-container {
display:flex;
align-items:center;
border-bottom:1px solid #DFDFDF
}

.sidebar-search-page {
background:#FFF;
border-radius:4px;
opacity:1;
width:100%;
border-right:1px solid #DFDFDF;
border-left:1px solid #DFDFDF
}

.page-head-icon {
padding:5px
}

.page-icon-container:hover {
background-color:#FFF;
color:#000;
cursor:pointer
}

.searcher-page {
width:100%;
padding:5px;
height:100%;
border:none;
outline:none;
font-family:'Roboto';
color:#74818D;
font-weight:400;
font-size:14px;
text-align:center
}

.page-bar-grid {
display:block;
position:relative;
place-items:left;
padding:8px;
max-width:100%;
overflow-y:scroll;
scrollbar-width:thin;
-ms-overflow-style:none;
overflow-x:hidden;
padding:10px;
direction:rtl;
z-index:5
}

.page-bar-workspace {
height:87vh
}

.page-bar-editor {
height:90vh
}

.page-bar-grid-item {
height:100px;
width:100%;
cursor:pointer;
transition:all .3s ease;
margin:6px 0;
border-bottom:1px solid #DFDFDF;
padding:6px 0;
direction:rtl
}

.page-container {
display:flex;
align-items:center;
width:100%;
height:100%;
flex-direction:column
}

.page-images {
width:100%;
height:100%;
box-shadow:0 1px 4px #0000001A;
padding:3px;
transition:all .3s ease;
border:2px solid transparent
}

.page-images-active {
width:100%;
height:100%;
box-shadow:0 1px 4px #0000001A;
padding:3px;
border:2px solid #0078d4;
transition:all .3s ease
}

.page-no {
font-size:12px;
font-weight:700;
font-family:'Roboto';
width:100%;
text-align:center;
padding:3px;
transition:all .3s ease
}

.page-no-active {
font-size:12px;
font-weight:700;
font-family:'Roboto';
width:100%;
text-align:center;
background-color:#0078d4;
color:#FFF;
padding:3px;
transition:all .3s ease
}

.display-none {
display:none
}

.workspace-input-itrate {
height:86vh;
overflow-y:scroll;
scrollbar-width:thin;
-ms-overflow-style:none;
padding:8px;
margin-top:15px
}

.ailaysa-canvas-text-postediting {
display:flex;
align-items:center;
flex-direction:column;
justify-content:left;
width:100%;
padding:20px;
background:#FFF;
border:2px solid #0074D3;
border-radius:10px;
opacity:1;
margin-bottom:10px
}

.postediting-head-text {
width:100%;
padding-bottom:10px;
border-bottom:1px solid #DFDFDF
}

.source-text {
text-align:left;
font-size:14px;
font-weight:400;
font-style:normal;
font-family:'Roboto';
letter-spacing:0;
color:#3C4043;
opacity:1;
margin-bottom:10px
}

.postediting-tags {
display:flex;
align-items:center;
justify-content:left;
gap:10px
}

.tag {
background:#EBEBEB!important;
border-radius:2px;
padding:3px 6px;
text-align:left;
font-family:'Roboto';
font-size:12px;
font-weight:400;
font-style:normal;
letter-spacing:0;
color:#5F6368;
opacity:1
}

.translated-postediting-canvas-area {
width:100%;
padding-top:10px
}

.ailaysa-canvas-textarea {
width:100%;
margin-bottom:15px;
border:none;
overflow-y:scroll;
scrollbar-width:thin;
-ms-overflow-style:none;
text-align:left;
font-size:14px;
font-weight:400;
font-style:normal;
font-family:'Roboto';
letter-spacing:0;
color:#3C4043;
opacity:1;
outline:none
}

.post-editing-confirmation {
display:flex;
align-items:center;
justify-content:space-between
}

.post-editing-toolbar {
display:flex;
align-items:center;
gap:10px
}

.unconfirmed-div {
background:rgba(255,159,33,0.2);
border-radius:4px;
display:flex;
align-items:center;
gap:5px;
padding:6px 9px
}

.confirmed-div {
background:rgba(33,255,88,0.2);
border-radius:4px;
display:flex;
align-items:center;
gap:5px;
padding:6px 9px
}

.confirmed-text {
text-align:left;
font-family:'Roboto';
font-size:12px;
font-weight:400;
font-style:normal;
letter-spacing:0;
color:#3C4043;
opacity:1
}

.unconfirmed-icon {
color:#FF9F21!important;
font-size:.5rem!important;
opacity:1
}

.confirmed-icon {
color:rgba(33,255,88)!important;
font-size:.5rem!important;
opacity:1
}

.unconfirmed-text {
text-align:left;
font-family:'Roboto';
font-size:12px;
font-weight:400;
font-style:normal;
letter-spacing:0;
color:#3C4043;
opacity:1
}

.posteditor-icons-sec {
color:#5F6368;
opacity:1;
cursor:pointer;
transition:all .3s ease;
font-size:2rem!important;
border-radius:50%;
padding:5px!important
}

.posteditor-icons-sec:hover {
background:#EBEBEB;
border-radius:50%;
transition:all .3s ease
}

.language-wrapper {
width:100%;
height:100vh;
display:flex;
align-items:center;
justify-content:center;
flex-direction:column
}

.header-language-selection-page {
position:absolute;
top:20px;
left:20px;
cursor:pointer;
transition:ease .3s all
}

.language-selection-page-back {
display:flex;
gap:10px;
padding:10px
}

.language-selection-page-back h4 {
font-weight:600
}

.header-language-selection-page:hover {
background:#E8F0FE;
color:#0078D4!important;
transition:ease .3s all;
cursor:pointer
}

.inner-language-wrap {
width:350px;
display:flex;
flex-direction:column;
gap:20px;
font-size:500px;
width:430px
}

.inner-language-wrap .language-select-button-wrap {
display:flex;
gap:20px
}

.inner-language-wrap .language-select-button-wrap button {
padding:19px;
border-radius:3px
}

.inner-language-wrap .language-select-button-wrap button:nth-child(1) {
background-color:#0078D4;
color:#FFF;
width:216px;
height:40px
}

.inner-language-wrap .language-select-button-wrap button:nth-child(2) {
background-color:#EBEBEB;
color:#3C4043;
width:103px;
height:40px
}

.inner-language-wrap>div>div {
margin-top:10px
}

.inner-language-wrap>div h4 {
font:normal normal medium 14px/19px Roboto;
letter-spacing:0;
color:#1D1F20
}

.colorbar {
display:flex;
gap:10px;
padding:2px 10px;
overflow:auto;
overflow-y:hidden;
max-width:500px
}

.colorbar p {
margin-bottom:0!important
}

.colorbar::-webkit-scrollbar {
height:6px
}

.colorbar::-webkit-scrollbar-thumb:horizontal {
background:#9f9f9f;
border-radius:10px
}

.inner-language-wrap>div .css-qc6sy-singleValue {
color:#0078D4!important
}

.inner-language-wrap>div .css-1rhbuit-multiValue.select__multi-value {
background-color:#D6E9F8;
color:#0078D4
}

.inner-language-wrap>div .select__multi-value__remove:hover {
background-color:#b1cee5;
color:#0078D4
}

.inner-language-wrap>div .css-12jo7m5.select__multi-value__label {
color:#0078D4
}

.css-tlfecz-indicatorContainer {
padding:0!important
}

.borderstyle-div {
padding:15px!important;
width:250px
}

.bordersize>div {
display:flex;
gap:5px;
flex-direction:column
}

.bordersize>div input {
width:80%
}

.borderdefault {
margin-top:10px;
display:flex;
flex-direction:column
}

.borderdefault>div {
margin-top:10px;
display:grid;
grid-template-columns:repeat(auto-fit,minmax(10px,1fr));
gap:10px
}

.bordersize .drag-box-container {
display:flex!important;
flex-direction:row!important
}

.bordersketch {
margin-top:10px!important;
width:208px!important
}

.colorselectborder .bordersketch {
padding:0!important
}

.twitterplacer {
width:230px!important;
margin-top:10px!important
}

.twitterplacer>div:nth-child(-n+2) {
display:none
}

.eyeinborder {
width:28px!important;
position:relative!important;
right:-161px!important;
top:-38px!important
}

.element-toolbar {
display:flex;
align-items:center;
transition: ease .3s all;
}

.css-26l3qy-menu {
z-index:9!important
}

.css-11fff28-control {
border:none!important;
border-radius:0!important;
z-index:2!important
}

.css-3s0tz6-control {
border:none!important;
border-radius:0!important;
z-index:9!important
}

.css-1dxu2g8-menu {
margin:0!important;
z-index:9!important
}

.css-1gtu0rj-indicatorContainer {
padding:0!important
}

.borderproperty,.borderstyle-container,.spacecontainer {
display:flex;
height:100%;
gap:10px;
align-items:center;
justify-content:center
}

.shapes-div-wrap {
padding:10px!important
}

.shapes-div-wrap strong {
display:flex;
justify-content:space-between;
margin:10px 0
}

.shapes-div-wrap .shapes-div {
width:200px;
display:grid;
grid-template-columns:33% 33% 33%;
gap:10px
}

.shapes-div>div {
width:40px;
height:50px
}

.shapes-div>div img {
width:40px;
height:50px
}

.singleelementcolor {
width:220px!important;
box-shadow:none!important
}

.twitter-picker>div:nth-child(-n+2) {
display:none
}

.eyecplacer {
position:relative;
right:-163px;
top:-39px
}

.header-project-setup-align-wraph-picker,.twitter-picker {
box-shadow:none!important
}

.div-fontcolorsection {
overflow:hidden;
padding:10px
}

.div-fontcolorsection .eyecplacer {
width:100%;
left:0;
top:-2px
}

.div-fontcolorsection
.singleelementcolor-sketch {
box-shadow:none!important
}

.drag-box-container {
display:flex;
align-items:center;
justify-content:space-between;
margin-bottom:10px
}

.drag-me {
color:#5F6368;
transition:ease .3s all;
border-radius:4px
}

.drag-me:hover {
background:#E8F0FE;
color:#0078D4!important;
transition:ease .3s all;
cursor:move
}

.shapes-button {
color:#000!important;
text-align:center
}

.set-as-background-but {
background:#FFF;
border-radius:4px;
opacity:1;
padding:5px 9px;
outline:none;
border:none;
text-align:center;
font-weight:700;
font-style:medium;
font-size:13px!important;
font-family:'Roboto';
letter-spacing:0;
display:flex;
align-items:center;
transition:ease .3s all
}

.background-confirmation-container {
display:flex;
align-items:center;
gap:15px
}

.set-as-background-but:hover {
background:#E8F0FE;
color:#0078D4!important;
transition:ease .3s all;
cursor:pointer
}

.confirm-ic {
padding:2px;
color:#5CCF51!important
}

.close-ic {
padding:2px;
color:red!important
}

.layer-sidebar {
width:120px;
padding:10px;
display:flex;
flex-direction:column;
gap:10px;
border-left:1px solid #DFDFDF;
z-index:2;
background-color:#fff
}

.layer-heading {
font-weight:Roboto;
font-size:14px;
font-weight:bold;
text-align:center;
width:100%;
color:#5E6368;
padding-bottom:5px;
border-bottom:1px solid #DFDFDF
}

.layer-inner-wrap {
display:flex;
gap:20px
}

.layer-inner-thumbs-container {
width:100px;
height:100px;
background-image:url(../icons/transparent.jpg);
background-position:center;
background-repeat:no-repeat;
background-size:cover;
text-align:center;
margin-top:10px;
box-shadow:0 1px 4px #0000001A!important
}

.layer-inner-thumbs-container-active {
width:100px;
height:100px;
background-image:url(../icons/transparent.jpg);
background-position:center;
background-repeat:no-repeat;
background-size:cover;
text-align:center;
margin-top:10px;
box-shadow:0 1px 4px #0000001A!important;
border:2px solid #9c0000
}

.layer-inner-thumbs {
width:90%;
height:100%;
object-fit:contain
}

.layer-name {
font-size:14px;
font-weight:400;
font-family:'Roboto';
font-style:normal
}

.layer-container {
width:100%;
overflow-y:scroll;
scrollbar-width:thin;
-ms-overflow-style:none;
overflow-x:hidden;
height:89vh;
padding:0 5px
}

.footer2 {
width:100%;
z-index:1;
position:absolute;
left:0;
bottom:0;
left:50%;
-webkit-transform:translateX(-50%);
transform:translateX(-50%);
border-top: 1px solid #DFDFDF;
}

.page-container2 {
display:inline-flex;
background:#FFF;
box-shadow:0 1px 4px #0000001A;
border-radius:4px;
gap:5px;
width:100%;
position:relative
}

.page {
background:#FFF;
border-radius:4px;
opacity:1;
display:flex;
align-items:center;
flex-direction:column;
gap:5px;
padding:8px;
width:5%;
border-right:1px solid #DFDFDF
}

.open-close-page-bar {
position:absolute;
top:-18px;
background:#FFF;
padding:0 5px;
left:48%;
cursor:pointer;
border-top:1px solid #DFDFDF;
z-index:3
}

.open-close-page-bar::after {
content:"";
position:absolute;
background-color:#FFF;
width:50%;
top:1px;
padding:2px;
height:100%;
border-left:1px solid #DFDFDF;
transform:rotate(121deg);
z-index:2
}

.open-close-page-bar::before {
content:"";
position:absolute;
background-color:#FFF;
width:50%;
top:1px;
left:-20px;
padding:2px;
height:100%;
border-right:1px solid #DFDFDF;
transform:rotate(-121deg);
z-index:2
}

.pages-preview-container {
width:100%;
white-space:nowrap;
overflow-x:scroll;
scrollbar-width:thin;
-ms-overflow-style:none;
overflow-y:hidden
}

.separator {
background:#DFDFDF;
width:1px;
height:60%
}

.searcher-page2 {
width:100%;
padding:5px;
height:100%;
border:none;
outline:none;
font-family:'Roboto';
color:#74818D;
font-weight:400;
font-size:14px;
text-align:center;
border-top:1px solid #DFDFDF;
border-bottom:1px solid #DFDFDF
}

.page-bar-grid-item2 {
height:100px;
width:100px;
cursor:pointer;
transition:all .3s ease;
padding:6px 0;
display:inline-block;
text-align:center;
padding:10px;
margin-bottom: 10px;
position: relative;
}

.page-inner-container2 {
display:flex;
align-items:center;
width:100%;
height:100%;
flex-direction:column
}

.pages-preview-container::-webkit-scrollbar-track {
padding:0 5px;
background:#fff;
z-index:3
}

.pages-preview-container::-webkit-scrollbar {
height:8px!important;
z-index:3
}

.pages-preview-container::-webkit-scrollbar-thumb {
background-color:#c3c4c6;
border-radius:50px;
border:2px solid #fff;
z-index:3
}

.footer3 {
z-index:2;
bottom:0;
right:50%;
padding:0 5px;
position:absolute;
background-color:#fff
}

.container.graphictab {
display:flex;
flex-direction:column;
position:relative;
width:100%;
height:100vh;
padding:0;
background:#FFF;
word-break:break-all
}

.bloc-tabs {
display:flex;
border-bottom:1px solid #E1E5E6
}

.tabs {
padding:10px;
text-align:center;
width:50%;
background:#fff;
cursor:pointer;
border-bottom:1px solid rgba(0,0,0,0.274);
box-sizing:content-box;
position:relative;
outline:none;
font-family:'Roboto';
font-size:14px;
font-style:normal;
color:#5F6368;
font-weight:500
}

.tabs:not(:last-child) {
border-right:1px solid rgba(0,0,0,0.274)
}

.active-tabs {
background:rgba(0,120,212,0.1);
border-bottom:1px solid transparent
}

.active-tabs::before {
content:"";
display:block;
position:absolute;
bottom:-2px;
left:50%;
transform:translateX(-50%);
width:calc(100% + 2px);
height:2px;
background:#5893f1
}

.container.graphictab button {
border:none
}

.content-tabs {
flex-grow:1;
box-sizing:border-box
}

.content {
background:#FFF;
width:100%;
height:100%;
display:none
}

.content h2 {
padding:0 0 5px
}

.content hr {
width:100%;
height:2px;
background:#222;
margin-bottom:5px
}

.content p {
width:100%;
height:100%
}

.active-content {
display:block
}

.container.graphictab .grid-photos {
height:82vh
}

.graphic-shapes {
display:flex;
flex-direction:column
}

.graphic-shapes img {
width:70px;
height:70px
}

.text-effects {
height:32px;
display:grid;
align-items:center;
justify-content:center
}

.text-effects>span {
font-weight:500;
display:flex;
align-items:center
}

.text-effects-wrapper.box {
width:250px;
padding:15px
}

.text-effects-wrapper.box .text-filter-div {
display:flex;
margin-top:20px;
justify-content:space-between
}

.text-effects-grid-items-act {
background-color:#dbebf8;
color:#0078D4!important;
font-weight:500
}

.text-effects-wrapper.box .text-filter-div>div {
height:50px;
width:100%;
display:grid;
place-items:center;
margin-bottom:20px;
font-size:100%
}

.text-effects-wrapper.box .text-filter-div>div>span {
font-size:32px;
font-weight:500
}

.text-effects-head-adjust {
margin:10px 0;
font-weight:500;
font-size:25px
}

.text-grid.tshadow {
width:100%;
text-align:center;
text-shadow:0 5px 10px rgba(0,0,0,0.7)
}

.text-grid.tbackground {
width:100%;
text-align:center;
background-color:#1eb4efb6
}

.text-grid.tStroke {
width:100%;
text-align:center;
-webkit-text-stroke-width:1px;
-webkit-text-stroke-color:red
}

.text-effects-adjust {
display:flex;
flex-direction:column
}

.text-effect-range-container {
display:flex;
flex-direction:column
}

.div-spacesection {
width:100%
}

.opacitymenu ul {
width:250px!important
}

.stroke-span {
font-size:25px;
font-weight:500
}

.opacity-menudiv {
display:flex;
flex-direction:column
}

.opacity-menudiv>span {
padding:0 15px
}

.inpaintbar {
height:46px;
background-color:#fff;
display:flex;
align-items:center;
justify-content:center;
box-shadow:0 1px 4px #0000001a;
gap:10px;
border-radius:5px;
padding:5px 10px;
z-index: 1;
position: absolute;
/* left: 50%; */
/* transform: translateX(-50%); */
top: 15px;
left: 15px;
}

.inpaintbar>span {
height:36px;
display:flex;
align-items:center;
justify-content:center
}

.inpaintbar>span>svg {
height:32px
}

.div-opacitysection {
padding:5px
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
box-shadow:0 1px 4px #0000001A!important
}

.download-headings {
display:flex;
align-items:center;
gap:10px
}

.download-list-flex {
display:flex;
align-items:center;
gap:10px;
flex-direction:column
}

.file-size-div {
width:100%
}

.heading-list-down-lab {
font-style:Roboto;
font-weight:400;
font-size:13px;
font-style:normal
}

.innter-down {
font-size:13px;
background:#0078D4!important;
border-radius:3px!important;
opacity:1!important;
padding:6px 15px!important;
color:#FFF!important;
text-align:center
}

.slider-file {
width:100%
}

.file-size-div {
width:100%;
display:flex;
flex-direction:column;
gap:5px
}

.innerText {
text-align:center;
font-size:12px;
font-weight:bolder;
font-family:'Roboto',sans-serif
}

.brushsize>.css-1nbic85 {
margin-top:10px;
margin-left:10px
}

.contextmenu ul {
width:200px;
box-shadow:0 5px 10px rgba(0,0,0,1)!important
}

.sizemenu {
display:flex;
width:50px;
gap:10px;
max-height:350px;
align-items:center;
flex-direction:column;
overflow:auto
}

.sizemenu>span {
width:100%;
height:30px;
display:flex;
align-items:center;
justify-content:center;
padding:5px;
cursor:pointer
}

.sizemenu>span:hover {
background-color:#E8F0FE;
cursor:pointer
}

.Fontalign {
width:150px;
padding:15px
}

.Fontalignmenu {
padding:5px;
display:flex;
align-items:center;
justify-content:center;
flex-direction:column;
gap:10px
}

.Fontalignmenu>span {
width:100%;
display:flex;
align-items:center;
gap:10px
}

.Fontalignmenu>span:hover {
background-color:#E8F0FE;
cursor:pointer
}

.Fontcolormenu .drag-box-container {
width:100%
}

.text-effect-range-container-color .eyecplacer {
width:100%;
left:0!important;
top:1px
}

.Fontcolormenu .eyecplacer {
left:0!important;
top:-1px!important;
width:100%
}

.Fontcolormenu {
padding:10px;
display:flex;
align-items:center;
justify-content:center;
flex-direction:column
}

.Fontcolormenu>span {
width:100%;
display:flex;
align-items:center;
gap:10px
}

.Fontcolormenu>span:hover {
background-color:#E8F0FE;
cursor:pointer
}

.scriptmenu-div {
padding:15px
}

.scriptmenu {
display:flex;
gap:10px;
align-items:center;
justify-content:center;
flex-direction:column
}

.scriptmenu>span {
width:100%;
display:flex;
gap:10px;
align-items:center
}

.scriptmenu>span:hover {
background-color:#E8F0FE;
cursor:pointer
}

.positionnmenu {
width:250px;
display:grid;
grid-template-columns:50% 50%;
gap:10px
}

.Group {
padding:15px
}

.Group .Groupmenu {
display:flex;
align-items:center;
justify-content:center;
flex-direction:column;
gap:10px
}

.Group .Groupmenu>span {
width:100%;
display:flex;
align-items:center;
gap:10px
}

.Group .Groupmenu>span:hover {
background-color:#E8F0FE;
cursor:pointer
}

.flip {
padding:15px
}

.flip .flipmenu {
display:flex;
align-items:center;
justify-content:center;
flex-direction:column;
gap:10px
}

.flipmenu>span {
display:flex;
align-items:center;
gap:10px;
width:100%
}

.flipmenu>span:hover {
background-color:#E8F0FE;
cursor:pointer
}

.others {
padding:15px;
width:150px
}

.othersmenu {
display:flex;
align-items:center;
justify-content:center;
flex-direction:column
}

.othersmenu>span {
width:100%;
display:flex;
flex-direction:column
}

.speech-main-wrap {
width:100%;
height:100%
}

.ts-dictation {
display:flex;
align-items:center;
/* gap:5px; */
height:100%;
width:100%;
resize:none;
font-family:"Roboto";
font-style:normal;
font-weight:400;
font-size:16px;
line-height:1.5;
color:#3c4043;
background-color:#fff;
border-radius:5px;
padding:5px 8px;
position:relative
}

.ts-draft-editor {
height:40px;
width:250px;
overflow-x:auto;
overflow-y:hidden;
background-color:#fff
}

.speech-main-wrap .ts-draft-editor span[data-text="true"] {
white-space:nowrap!important
}

.start-listening-btn {
font-family:"Roboto";
font-style:normal;
font-weight:400;
font-size:16px;
line-height:1.5;
color:#3c4043;
height:100%
}

.mic-icon {
display:flex;
align-items:center;
gap:5px
}

.sidebar-search-lang-select {
display:flex;
align-items:center;
padding:5px;
gap:5px;
background:#ffffff;
justify-content:space-between;
border-radius:4px;
opacity:1;
width:100%
}

.rest {
height:60vh
}

.clear-div {
text-align:right
}

.clear-but {
border-radius:50%;
padding:5px;
transition:.3s all ease;
opacity:1
}

.clear-but .icon {
color:#5F6368;
padding:2px;
border-radius:4px
}

.clear-but .icon:hover {
background-color:#EBEBEB;
transition:.3s all ease;
cursor:pointer
}

.accordion-texttool {
width:250px;
padding:15px 0 0
}

.accordion-texttool>p {
display:flex;
margin:10px 0;
padding:0 20px;
align-items:center;
gap:10px
}

.accordion-texttool .drag-box-container {
padding:0 15px
}

.btns:hover {
background-color:#E8F0FE;
cursor:pointer
}

.accordion-texttool p:nth-of-type(2)>span:nth-of-type(4) {
position:relative;
top:2.5px
}

.other-styles {
justify-content:normal!important
}

.accordion-texttool p span:nth-child(1) {
width:60px
}

.btns {
width:28px;
height:36px;
display:flex;
align-items:center;
justify-content:center;
border-radius:4px
}

.btns:hover {
background-color:#E8F0FE;
color:#0078D4!important;
transition:ease .3s all;
cursor:pointer
}

#panel1d-header {
background-color:#fff
}

.position .drag-box-container {
padding:15px;
margin-bottom:0!important
}

.moretools-icons {
display:grid;
grid-template-columns:1fr 1fr;
gap:10px;
padding:0 15px;
columns:2;
margin:10px 0
}

.moretools-icons>span {
display:flex;
gap:5px
}

.positionbtn {
display:flex;
padding:5px;
white-space:nowrap
}

.moretools-icons>span:hover,.positionbtn:hover {
background-color:#E8F0FE;
color:#0078D4!important;
transition:ease .3s all;
cursor:pointer
}

.rdw-editor-wrapper {
border:1px solid #D9DBDF;
border-radius:4px;
padding:8px
}

.main-tabs {
height:auto
}

.main-tabs-heading {
display:flex;
justify-content:space-between;
padding:10px 0
}

.main-tab-search {
display:flex;
align-items:center;
padding:12px;
border-radius:4px;
opacity:1;
width:100%;
background-color:#e9ebee;
gap:8px;
padding:7.5px 10.5px
}

.main-tab-content {
padding: 10px
}

.grid-design-tab {
margin:10px 0 0
}

.main-tab-grid {
position:relative;
border-radius:4px;
position:relative;
transition:all .3s ease
}

.main-tab-grid:hover .delete-icon {
display:block!important;
transition:all .3s ease
}

.main-tab-grid .delete-icon {
top:0;
right:0
}

.main-tab-grid>div>div {
display:flex;
align-items:center
}

.main-tab-grid>div>div>div {
display:flex;
flex-direction:column;
white-space:nowrap;
gap:5px;
border-left: 1px solid #DFDFDF;
padding-left: 6px;
align-self:normal;
}

.main-tab-grid>div>div>div .details:nth-child(1) span:nth-child(2),.image-details>.details:nth-child(1) span:nth-child(2) {
width:125px;
overflow:hidden;
display:inline-block;
text-overflow:ellipsis;
white-space:nowrap
}

.main-bar-image {
width:100%;
height:100%;
box-shadow:0 1px 4px #0000001a
}

.main-bar-image-temp {
    width:100%;
    height:100%;
    box-shadow:0 1px 4px #0000001a
    }

.tab-heading {
white-space:nowrap
}

.checkbox-translate-image {
display:flex
}

.checkbox-translate-image .checkbox {
width:30px;
display:flex;
align-items:center
}

.pharaphrasing-container {
width:100%;
resize:none;
height:532px;
overflow-y:scroll;
overflow-x:hidden;
font-family:"Roboto";
font-style:normal;
font-weight:400;
font-size:16px;
line-height:1.5;
color:#3c4043;
border:1px solid #D9DBDF;
background-color:#fff;
margin-top:5px;
border-radius:5px;
padding:20px 17px;
text-align:left;
position:relative
}

.pharaprase-items {
display:flex;
cursor:pointer;
gap:10px;
margin-bottom:5px;
padding:5px;
font-size:12px
}

.pharaprase-items:hover {
font-weight:700
}

.font-case {
font-size:18px;
padding:3px;
font-weight:500;
}

.log-out {
cursor:pointer
}

.download-options {
width:200px
}

.download-collapse {
width:100%
}

.download-collaspe-card {
border:none!important
}

.ai-sl-tl-btn {
padding:9px 13px 9px 20px;
background-color:transparent;
border-radius:3px;
display:flex;
align-items:center;
gap:10px;
cursor:pointer;
transition:.3s all ease
}

.ai-sl-tl-btn .text {
font-style:normal;
font-weight:500;
font-size:14px;
line-height:1.5;
color:#3c4043
}

.ai-sl-tl-btn .text-tar {
font-style:normal;
font-weight:500;
font-size:14px;
line-height:1.5;
color:#3c4043;
padding-right:4px
}

.language-modal-wrapper {
display:flex;
align-items:center;
flex-direction:column;
gap:30px
}

.input-form-field,.form-fields {
width:100%
}

.form-fields.target-lang {
width:fit-content;
width:-moz-fit-content;
min-width:65%;
max-width:100%
}

.language-modal-wrapper {
font-style:normal;
font-weight:500;
font-size:16px;
line-height:1.5;
color:#222;
margin-bottom:10px
}

.language-modal-wrapper label {
margin-bottom:5px
}

.modal-close-icon {
width:100%;
text-align:end;
margin-bottom:15px
}

.ai-lang-select {
padding:10px 19px 10px 21px;
background-color:transparent;
display:flex;
justify-content:space-between;
align-items:center;
transition:.4s all ease;
border-radius:3px;
border:1px solid #ced4da;
cursor:pointer
}

.placeholder {
display:inline-block;
min-height:1em;
vertical-align:middle;
cursor:pointer!important;
background-color:transparent!important;
opacity:.5
}

.next-container {
margin-top:20px;
text-align:center
}

.ai-large-file-alert-modal,.ai-tar-lang-select-modal,.ai-lang-select-modal {
z-index:1080!important;
backdrop-filter:blur(2px);
-webkit-backdrop-filter:blur(2px)
}

.ai-tar-lang-select-modal .rodal-dialog {
border:none;
box-shadow:0 3px 14px #00000038!important;
border-radius:10px!important;
height:758px!important;
padding:0!important;
width:100%!important;
max-width:85%
}

.ai-large-file-alert-modal .rodal-dialog {
border:none;
box-shadow:0 3px 14px #00000038!important;
border-radius:10px!important;
height:250px!important;
padding:20px!important;
width:350px!important;
max-width:85%
}

.ai-lang-select-modal .rodal-dialog {
border:none;
box-shadow:0 3px 14px #00000038!important;
border-radius:10px!important;
height:750px!important;
padding:0!important;
width:100%!important;
max-width:85%
}

.ai-source-language-cont {
padding:25px;
height:inherit;
position:relative
}

.lang-modal-search-area.focused {
height:36px!important;
width:510px!important
}

.lang-modal-search-area {
margin-bottom:23px;
height:36px;
border:1px solid #ced4da;
display:flex;
align-items:center;
padding:6px 12px 6px 16px;
width:510px;
border-radius:3px
}

.ai-src-language-part {
height:607px
}

ul.ai-source-langs-list {
display:flex;
flex-direction:column;
flex-wrap:wrap;
height:inherit;
padding-left:0;
margin-bottom:0;
list-style-type:none;
overflow:auto
}

.modal-close-btn {
height:40px;
width:40px;
display:flex;
align-items:center;
justify-content:center;
border-radius:50%;
background:transparent;
transition:.4s all ease
}

.borderstyle-container>span {
color:#5F6368!important;
cursor:pointer
}

.lang-modal-wrapper {
position:relative;
height:100%
}

.lang-close {
position:absolute;
right:10px;
top:10px;
z-index:1080
}

.modal-close-btn:hover {
background:#e9eaea;
cursor:pointer
}

.lang-modal-search-area .search-input {
border:none;
flex-grow:1;
font-style:normal;
font-weight:400;
font-size:16px;
line-height:1.5;
color:#3C4043;
outline:none
}

.lang-modal-search-area .icon-wrap {
height:24px;
width:24px;
display:flex;
align-items:center;
justify-content:center;
border-radius:50%;
background:transparent;
transition:.4s all ease
}

.lang-modal-search-area {
font-size:20px;
color:#7F7F7F
}

ul.ai-source-langs-list li.list {
padding:5px;
color:#3c4043;
font-size:14px;
line-height:24px;
font-weight:400;
border-radius:3px;
margin-bottom:.4rem;
margin-right:.4rem;
padding-right:25px;
width:fit-content
}

ul.ai-source-langs-list li.list:hover {
background-color:#f1f1f1;
cursor:pointer
}

ul.ai-source-langs-list li.selected:hover,ul.ai-source-langs-list li.selected {
background-color:#e8f0fe
}

ul.ai-source-langs-list li.selected .checked-icon {
opacity:1
}

ul.ai-source-langs-list::-webkit-scrollbar-track {
box-shadow:none;
-webkit-box-shadow:none;
-moz-box-shadow:none;
background-color:#fff
}

ul.ai-source-langs-list::-webkit-scrollbar {
width:18px
}

ul.ai-source-langs-list::-webkit-scrollbar-thumb {
border:7px solid transparent;
background-clip:padding-box;
border-radius:50px;
background-color:#0000004d
}

.checked-icon {
opacity:0;
padding-right:.4rem
}

.new-padd-style {
padding:10px 18px!important
}

.login-btn {
color:#fff;
font-size:15px;
line-height:19px;
font-weight:700;
padding:10px 40px
}

.search-not-found-wrapper {
display:flex;
align-items:center;
justify-content:center;
flex-direction:column;
gap:14px;
height:100%
}

.search-not-found-wrapper h1 {
font-style:normal;
font-weight:500;
font-size:24px;
line-height:1.5;
color:#3C4043
}

.search-not-found-wrapper img {
width:68px;
height:74px
}

.ReactModal__Overlay {
background:rgba(0,0,0,0.3)!important
}

.modal-lang-wrap-head {
text-align:left;
font-size:18px;
font-weight:700;
font-style:normal;
font-family:'Roboto';
letter-spacing:0;
color:#222;
opacity:1;
margin-bottom:30px
}

.language-select-button-wrap {
display:flex;
align-items:center;
gap:10px;
margin-top:30px;
justify-content:end
}

.create-multi-canvas-button {
background:#0078D4!important;
border-radius:3px!important;
opacity:1!important;
padding:10px 20px!important;
text-align:center!important;
font-size:15px!important;
font-style:medium!important;
font-family:'Roboto'!important;
font-weight:medium!important;
letter-spacing:0!important;
color:#FFF!important;
border: none;
}

.canvas-button {
background:#EBEBEB!important;
border-radius:3px!important;
opacity:1!important;
padding:10px 28px!important;
text-align:center!important;
font-size:15px!important;
font-family:'Roboto'!important;
font-style:medium!important;
font-weight:medium!important;
letter-spacing:0!important;
color:#3C4043!important;
opacity:1!important;
border: none;
}

.down-arrow-icon {
color:#000!important;
opacity:.33!important
}

.rodal-dialog {
position:absolute;
top:0;
left:0;
right:0;
bottom:0;
margin:auto;
z-index:101;
padding:30px!important;
background:#fff;
border-radius:10px!important;
box-shadow:0 3px 14px #00000038
}

.bs-popover-auto {
padding:8px!important
}

.labguage-select-pop {
display:flex;
align-items:center;
font-family:'Roboto';
font-weight:700;
padding:2px;
margin-bottom:5px;
cursor:pointer
}

.grid-item-text {
padding:5px;
cursor:pointer;
width: 100%;
}

.col-first{
    width: 50%;
}

.text-design-items-wrapper{
    background-color:#fff;
    box-shadow:0 1px 4px #0000001a;
    width: 100%;
    padding: 10px;
    height: 120px;
    display: flex;
    align-items: center;
}

.modal-warning-wrap-head {
text-align:center;
font-size:18px;
font-weight:700;
font-family:'Roboto';
font-style:normal
}

.warning-yes-no-wraper {
margin-top:20px;
display:flex;
align-items:center;
justify-content:space-between
}

.yes-no-but {
padding:10px 15px;
font-family:'Roboto';
font-size:12px;
font-style:normal;
font-weight:400
}

.yes-but {
background-color:#0078D4;
color:#FFF;
border-radius:3px
}

.no-but {
background-color:#EBEBEB;
border-radius:3px
}

.borderstyle-div .border.eyeinborder {
position:absolute;
left:0;
width:100%!important;
top:6px!important
}

.splitview {
display:flex;
align-items:center;
height:100%
}

.soloView {
height:100%;
width:100%
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
pointer-events:auto
}

.MuiPopover-root.react-draggable.react-draggable-dragged.MuiModal-root {
pointer-events:none
}

.button-AiNavButton {
border-radius:2px;
padding:6px 16px;
font-size:.875rem;
min-width:64px;
box-sizing:border-box;
font-family:"Roboto","Helvetica","Arial",sans-serif;
font-weight:500;
line-height:1.75;
border-radius:4px;
letter-spacing:.02857em
}

span.ripple {
position:absolute;
border-radius:50%;
transform:scale(0);
animation:ripple 600ms linear;
background-color:rgba(255,255,255,0.7)
}

@keyframes ripple {
to {
transform:scale(4);
opacity:0
}
}

.sidebaropen-bar {
position:absolute;
width:fit-content;
width:-moz-fit-content;
height:fit-content;
height:-moz-fit-content;
top:80px;
left:-34px;
transition:all .3s ease;
border:none;
outline:none;
background-color:transparent;
z-index:0;
cursor:pointer
}

.sidebaropen-bar-bottom {
position:absolute;
width:fit-content;
width:-moz-fit-content;
height:fit-content;
height:-moz-fit-content;
bottom:-65px;
right:50%;
transition:all .3s ease;
border:none;
outline:none;
background-color:transparent;
z-index:0;
cursor:pointer
}

.sidebaropen-bar-bottom-update {
position:absolute;
width:fit-content;
width:-moz-fit-content;
height:fit-content;
height:-moz-fit-content;
bottom:-8px;
right:50%;
transition:all .3s ease;
border:none;
outline:none;
background-color:transparent;
z-index:0;
cursor:pointer
}

.rotate-down-svg {
transform:rotate(90deg)
}

.open-close:hover {
left:-60px!important;
transition:all .3s ease;
cursor:pointer
}

.sidebaropen-bar.left-side-bar .sidepanel-leaf {
background:#fff;
box-shadow:0 1px 1px 0 #3c0000 0 1px 3px 1px #900;
border-radius:25px 0 0 25px;
padding:0 11px;
width:100px;
height:35px;
display:flex;
align-items:center;
justify-content:flex-start
}

.sidebaropen-bar.left-side-bar .side-bar-arrow-ai-writter {
font-size:12px!important;
color:#5F6368!important
}

.sidebaropen-bar-bottom.left-side-bar .sidepanel-leaf {
background:#fff;
box-shadow:0 1px 1px 0 #3c0000 0 1px 3px 1px #900;
border-radius:25px 25px 0 0;
padding:0 11px;
width:35px;
height:100px;
display:flex;
padding:14px 0 0 0;
align-items:start;
justify-content:center
}

.sidebaropen-bar-bottom.left-side-bar .side-bar-arrow-ai-writter {
font-size:12px!important;
color:#5F6368!important
}

.sidebaropen-bar-bottom-update.left-side-bar .sidepanel-leaf {
background:#fff;
box-shadow:0 1px 1px 0 #3c0000 0 1px 3px 1px #900;
border-radius:25px 25px 0 0;
padding:0 11px;
width:35px;
height:140px;
display:flex;
padding:9px 0 0 0;
align-items:start;
justify-content:center
}

.sidebaropen-bar-bottom-update.left-side-bar .side-bar-arrow-ai-writter {
font-size:12px!important;
color:#5F6368!important
}

.rotate-svg {
transform:rotate(90deg)
}

.open-close-bottom {
position:absolute;
background:#FFF;
cursor:pointer;
z-index:3;
border-radius:50%
}

.open-close-bottom:hover {
bottom:-40px!important;
transition:all .3s ease;
cursor:pointer
}

.active-search-bar {
background-color:#fff;
box-shadow: 0 1px 1px 0 rgb(65 69 73 / 30%), 0 1px 3px 1px rgb(65 69 73 / 15%)
}

.search-placeholder-text {
width:100%
}

.sketch-picker {
box-shadow:none!important
}

.save-text-template {
border:none;
padding:5px 10px;
border-radius:4px;
font-family:'Roboto';
font-size:13px;
font-weight:500;
font-style:normal
}

.alltoolbars hr {
opacity:1!important
}

.inpaint-div {
position:absolute;
z-index:-1;
transform-origin:top left
}

.rotate {
animation:rotation 3s infinite linear
}

@keyframes rotation {
from {
transform:rotate(359deg)
}

to {
transform:rotate(0deg)
}
}

.project-box-ai[data-placeholder]:empty:before {
content:attr(data-placeholder);
font-style:normal;
font-weight:400;
font-size:18px;
color:#000;
transition:.1s all ease;
position:relative;
top:0
}

.project-box-ai {
border:none;
background-color:transparent;
border:2px solid transparent;
border-radius:3px;
transition:.3s all ease;
width:auto;
width:-moz-fit-content;
font-style:normal;
font-weight:400;
font-size:18px;
color:#000;
line-height:1.5;
height:38px;
margin-bottom:0;
display:inline-block;
vertical-align:middle;
overflow:hidden;
text-overflow:ellipsis;
white-space:nowrap;
padding:5px 15px
}

.project-box-ai:not(:focus) {
text-overflow:ellipsis
}

.project-box-ai[contenteditable=true]:empty:before {
content:attr(data-placeholder);
pointer-events:none
}

.project-box-ai:focus {
outline:none;
border-color:#0073D6;
border-width:2px;
background-color:#fff;
transition:.1s all ease;
height:38px
}

.project-box-ai:hover {
transition:.2s all ease;
text-overflow:clip
}

.project-box-ai:focus {
text-overflow:clip
}

.project-box-ai:not(:focus) {
text-overflow:ellipsis
}

.my-wrok-icon {
width:18px;
height:18px;
}

.text-tools {
position:absolute;
display:flex;
gap:10px;
top:10px;
left:10px;
z-index:2;
transition: ease .3s all;
}

.MuiTooltip-arrow {
color:#2A2A2A!important
}

.MuiTooltip-tooltip {
background-color:#2A2A2A!important
}

.bullets {
display:flex;
flex-direction:column;
width:150px;
padding:15px;
gap:10px;
user-select:none
}

.bullets>span {
padding-left:10px
}

.bullets .bullet-tab-item-list {
display:flex;
gap:10px;
flex-wrap:wrap;
padding:0;
margin:0
}

.bullet-tab-item-list span {
display:flex;
align-items:center;
justify-content:center
}

.bullet-tab-item-list.symbols span {
width:25px;
height:37px
}

.bullet-tab-item-list span:hover {
cursor:pointer;
background-color:#E8F0FE
}

.bullet-tab-item-list:not(.symbols) span {
padding:0 5px
}

.bullets>span:hover {
background-color:#E8F0FE;
cursor:pointer
}

.text-bullet-list-tab-header {
padding:0;
margin:0;
display:flex;
gap:5px;
justify-content:space-between;
cursor:pointer
}

.text-bullet-list-tab-header span {
padding:0 10px;
border-radius:1px
}

.loading-json-blur {
-webkit-filter:blur(1px);
-moz-filter:blur(1px);
-o-filter:blur(1px);
-ms-filter:blur(1px);
filter:blur(1px)
}

#loading-wrapper {
position:absolute;
width:100%;
height:100%;
left:0;
top:0;
background-color:rgba(255,255,255,0.5);
display:none;
align-items:center;
justify-content:center
}

.loader span{
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #03a9f4;
    margin: 0 10px;
  }
  
  .loader span:nth-child(1){
      animation: 1s jump infinite ease-in-out;
  }
  
  .loader span:nth-child(2){
      animation: 1s 0.333s jump infinite ease-in-out;
  }
  
  .loader span:nth-child(3){
      animation: 1s 0.667s jump infinite ease-in-out;
  }
  
  @keyframes jump{
    0%{
      transform: translateY(0) scale(1);
    }
    30%{
      transform: translateY(-20px) scale(1.2);
    }
    75%{
      transform: translateY(0) scale(1);
    }
  
  }

/* .loader {
border-radius:50%;
position:relative;
margin:50px;
display:inline-block;
height:0;
width:0
}

.loader span {
position:absolute;
display:block;
background:#ddd;
height:20px;
width:20px;
border-radius:50%;
top:-20px;
perspective:100000px
}

.loader span:nth-child(1) {
left:60px;
animation:bounce2 1s cubic-bezier(0.04,0.35,0,1) infinite;
animation-delay:0s;
background:#ff756f
}

.loader span:nth-child(2) {
left:20px;
animation:bounce2 1s cubic-bezier(0.04,0.35,0,1) infinite;
animation-delay:.2s;
background:#ffde6f
}

.loader span:nth-child(3) {
left:-20px;
animation:bounce2 1s cubic-bezier(0.04,0.35,0,1) infinite;
animation-delay:.4s;
background:#01de6f
}

.loader span:nth-child(4) {
left:-60px;
animation:bounce2 1s cubic-bezier(0.04,0.35,0,1) infinite;
animation-delay:.6s;
background:#6f75ff
}

@keyframes bounce2 {
0%,56%,100% {
transform:translateY(0px)
}

25% {
transform:translateY(-30px)
}
} */

#triangle-down {
width:0;
height:0;
border-left:5px solid transparent;
border-right:5px solid transparent;
border-top:5px solid #747474
}

#triangle-up {
width:0;
height:0;
border-left:5px solid transparent;
border-right:5px solid transparent;
border-bottom:5px solid #747474
}

.lang-select-bar-font-fam {
display:flex;
align-items:center;
width:100%;
gap:15px
}

.lang-select-bar-font-fam .project-type-list__control {
width:100%
}

.lang-select-bar-font-fam .project-type-list__menu-list::-webkit-scrollbar {
width:4px;
height:0
}

.lang-select-bar-font-fam .project-type-list__menu-list::-webkit-scrollbar-track {
background:#f1f1f1
}

.lang-select-bar-font-fam .project-type-list__menu-list::-webkit-scrollbar-thumb {
background:#888
}

.lang-select-bar-font-fam .project-type-list__menu-list::-webkit-scrollbar-thumb:hover {
background:#555
}

.lang-select-bar-font-fam .css-b62m3t-container {
width:100%
}

.lang-select-bar-font-fam .label-lang {
font-size:14px;
font-family:'Roboto';
font-weight:400;
font-style:normal
}

.bg-container-height{
height: 35vh !important;
}

.ai-image-gen-wrapper .title {
display: flex;
align-items: center;
justify-content: space-between;
margin-bottom: 9px;
}

.ai-image-gen-wrapper .title {
text-align: left;
font-size: 14px;
font-weight: 500;
font-style: 'Roboto';
color: #222222;
opacity: 1;
}

.asterik-symbol{
color: #E74C3C !important;
padding-left: 4px;
text-decoration: none !important;
}

.ai-image-gen-wrapper .title .words-count{
font-size: 14px;
font-weight: normal;
line-height: 24px;
color: #9B9FA2;
}

.prompt-generating-textarea{
width: 100%;
padding: 12px 21px;
background: #FFFFFF ;
border: 1px solid #D3D8DC;
border-radius: 5px;
height: auto;
min-height: 140px;
max-height: 170px;
margin-bottom: 12px;
}

.ai-image-gen-wrapper{
width: 100%;
}

.prompt-generating-textarea:focus{
width: 100%;
background: #FFFFFF ;
box-shadow: 0px 0px 10px #0000001A;
border: 1px solid #0078D4;
outline: 1px solid #0078D4 !important;
border-radius: 5px;
height: auto;
max-height: 170px;
}

.prompt-generating-textarea::placeholder{
font-size: 14px;
font-weight: normal;
line-height: 24px;
color: #9B9FA2;
}

.aiGeneratingButtonFull{
background-color:#0078D4;
width: 100%;
box-shadow: none;
border-radius: 3px;
text-transform: none;
padding: 0;
border: none;
}

.aiGeneratingButtonFull:hover{
background-color: #0265b1;
box-shadow: none;
}

.generating-button.paste-but{
display: flex;
align-items: center;
justify-content: center;
text-align: center;
width: 100%;
position: relative;
padding: 13px 17px;
color: #ffffff;
}
.text-center {
text-align: center!important;
}

.generate-icon {
position: absolute;
right: 17px;
}

.heading-image-ai-tab {
font-size:13px;
font-weight:700;
width:100%
}

.name-image-ai-tab {
white-space:normal;
font-size:12px;
width:100%
}

.details-ai-tab {
font-family:'Roboto';
text-align:start;
width:100%;
display:flex;
align-items:start;
gap:5px;
flex-wrap: wrap;
}

.my-wrok-icon-ai-icon{
    width: 24px;
    height: 24px;
}

.close-toggle-sidebar {
    position: absolute;
    right: -13px;
    transform: rotate(180deg);
    cursor: pointer;
    top: 45%;
}

.close-toggle-sidebar-right {
    position: absolute;
    left: -12px;
    cursor: pointer;
    top: 45%;
}

.close-toggle-sidebar-down {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 50%;
    transform: rotate(90deg);
    top: -55px;
}


.ai-image-gen-wrapper{
    margin-top: 15px;
}

.font-family-thumbnail{
    height: 20px;
}

.tone-generatint-form-options{
    display: flex;
    align-items: center;
    width: 100%;
    gap: 21px;
    margin-bottom: 12px;
  }

  .last-select-row .content-generatint-form-options:first-child{
    width: 65%;
  }

  .last-select-row .content-generatint-form-options:last-child{
    width: 35%;
  }


  .content-generatint-form-options{
    width: 100%;

  }

  .tone-generatint-form-options {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
}

.content-generatint-form-options>div:nth-child(1) {
    margin-bottom: 9px;
}
.content-generatint-form-options>div {
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    font-style: 'Roboto';
    color: #222222;
    opacity: 1;
}

.css-1okebmr-indicatorSeparator, .css-109onse-indicatorSeparator {
    display: none !important;
}

.arrow-icon-color {
    color: #747474 !important;
}

.close-toggle-sidebar-down-outside{
    position: absolute;
    cursor: pointer;
    /* top: 0; */
    left: 50%;
    transform: rotate(90deg);
    bottom: -44px;
}

/* .myhighlight {
    border-radius: 3px; 
    background-color: #ddd; 
    
    color: transparent;
  
    line-height:90%;
    display: inline-block;
    border-bottom: 3px red solid;
    
  }

  .backdrop {
    position: absolute;
    z-index: 1;
    border: 2px solid transparent;
    background-color: transparent;
    overflow: none;
    pointer-events: none;
    transition: transform 1s;
  }
  
  .highlights {
      white-space: pre-wrap;
      word-wrap: break-word;
      color: transparent;
  }
  .highlights, .ailaysa-canvas-textarea{
    font-size:14px;
    font-weight:400;
    font-style:normal;
    font-family:'Roboto';
    letter-spacing:0;
    color:#3C4043;
  }

  .ailaysa-canvas-textarea{

  background-color: transparent;
  overflow: auto;
  resize: none;
  transition: transform 1s;
  border: 2px solid transparent;
  } */

  .template-global{
    /* margin-bottom: 10px; */
    position: relative;
  }

  .template-global:hover .delete-icon {
    display:block!important;
    transition:all .3s ease
    }


  .grid-template{
    height: 82vh;
    width: 100%;
    overflow: auto;
    /* display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    grid-gap: 1px; */
    /* grid-auto-rows: minmax(180px, auto); */
    /* grid-auto-flow: dense; */
  }

  .template-half{
    width:50%;
  }

  .new-design-button{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    /* line-height: 19px; */
    color: #ffffff;
    padding: 6px 12px 6px 7px;
    background-color: #0074d3;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 2px;
    cursor: pointer;
  }

  .new-design-button-icon{
    width: 18px !important;
    height: 18px !important;
  }

  .tool-container{
    position: absolute;
    top:5px;
    right:5px;
    background: #0074d3;
    color: #ffffff;
    border-radius: 4px;
  }

  .writter-tooltip-design-subhead{
    font-style: italic;
    font-size: 10px;
    opacity: 0.7;
    font-family: 'Roboto' !important;
    line-height: 1.4 !important;
    font-weight: 400 !important;
}

.writter-tooltip-design-head{
  font-size: 10px;
  font-family: 'Roboto' !important;
}

.tool-info-heading{
  font-size: 15px !important;
  font-family: 'Roboto' !important;
}


.delete-icon-uploads{

}

.grid-Inpaint-upload:hover .delete-icon-uploads {
  display:block!important;
  transition:all .3s ease
  }

  .delete-icon-uploads {
    position: absolute;
    padding:4px;
    right: 5px;
    top: 5px;
    display:none!important
    }
    
    .delete-icon-uploads:hover {
    /* padding:3px; */
    color:#000000;
    background-color:#ffffff;
    /* border-radius:50%; */
    cursor:pointer
    }



    
/* ruler  */


.canvas-editor-space div a {
  text-decoration: none;
  color: #333;
}
.canvas-editor-space div .ruler {
  position: absolute;
  top: 0;
  left: 0;
}
.canvas-editor-space div .ruler.horizontal {
  left: 0px;
  width: 100%;
  height: 30px;
}
.canvas-editor-space div .ruler.vertical {
  top: 0px;
  width: 30px;
  height: 100%;
}
.canvas-editor-space div .box {
  position: absolute;
  width: 30px;
  top: 0px;
  left: 0px;
  height: 30px;
  background: #f0f0f0;
  box-sizing: border-box;
  z-index: 21;
  border-right:1px solid #566656;
  border-bottom:1px solid #566656;
}


.canvas-editor-space div .container {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  max-width: calc(100% - 60px);
  width: 100%;
}

.canvas-editor-space div .badges {
  padding: 10px;
}
.canvas-editor-space div .badges img {
  height: 20px;
  margin: 0px 2px;
}
.canvas-editor-space div .buttons {
  text-align: center;
  margin: 0;
  padding: 10px;
}
.canvas-editor-space div .buttons a {
  margin: 0;
  position: relative;
  text-decoration: none;
  color: #333;
  border: 1px solid #333;
  padding: 12px 30px;
  min-width: 140px;
  text-align: center;
  display: inline-block;
  box-sizing: border-box;
  margin: 5px;
  transition: all ease 0.5s;
}
.canvas-editor-space div .buttons a:hover {
  background: #333;
  color: #fff;
}

.canvas-editor-space div p {
  padding: 0;
  margin: 0;
}

.canvas-editor-space div .dragit {
  font-weight: bold;
}

.canvas-editor-space div .scena-guides-guide .scena-guides-guide-pos{
    display: none;
}

.canvas-editor-space div .scena-guides-guide:hover .scena-guides-guide-pos{
    display: block;
}

.tg-list-item {
  margin: 0 2em;
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
  gap: 2px;
}
.tg-list-item label{
  cursor: pointer;

}
.tg-list-item h5 {
  color: #999;
}
.tgl {
  display: none;
}
.tgl, .tgl:after, .tgl:before, .tgl *, .tgl *:after, .tgl *:before, .tgl + .tgl-btn {
  box-sizing: border-box;
}
.tgl::selection, .tgl:after::selection, .tgl:before::selection, .tgl *::selection, .tgl *:after::selection, .tgl *:before::selection, .tgl + .tgl-btn::selection {
  background: none;
}
.tgl + .tgl-btn {
  outline: 0;
  display: block;
  width: 4em;
  height: 2em;
  position: relative;
  cursor: pointer;
  user-select: none;
}
.tgl + .tgl-btn:after, .tgl + .tgl-btn:before {
  position: relative;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
}
.tgl + .tgl-btn:after {
  left: 0;
}
.tgl + .tgl-btn:before {
  display: none;
}
.tgl:checked + .tgl-btn:after {
  left: 50%;
}

.tgl-flat + .tgl-btn {
  padding: 2px;
  transition: all 0.2s ease;
  background: #fff;
  border: 4px solid #c9c2c2;
  border-radius: 2em;
  transform: scale(0.8);
}
.tgl-flat + .tgl-btn:after {
  transition: all 0.2s ease;
  background: #c9c2c2;
  content: "";
  border-radius: 1em;
}
.tgl-flat:checked + .tgl-btn {
  border: 4px solid #0074d3;
}
.tgl-flat:checked + .tgl-btn:after {
  left: 50%;
  background: #0074d3;
}

.page-more-icon{
  position: absolute;
  top: 5px;
  right: 12px;
  display: none;
}

.page-bar-grid-item2:hover .page-more-icon{
  display: block;
}

.page-wise-more-wrap{
  padding:5px
}

.page-delte-individual{
  cursor: pointer;
}

.word-count-writter-wrapper {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 8px 18px;
  box-shadow: 0px 4px 10px #00000029;
  border-radius: 22px;
  border: none;
  background-color: #ffffff;
  cursor: auto !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 250px;
}

.word-count-number-str{
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 5px;
  background-color: #0074d3;
  color: #ffffff;
  border-radius: 4px 0 0 4px;

}
.word-count-number-str-setting{
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 5px;
  background-color: #0074d3;
  color: #ffffff;
  border-radius: 0 4px 4px 0;
  border-left: 1px solid #ffffff;
}

.word-count-number-str:disabled{
  opacity: 0.5;
}

.settings-insta-translate-icon{
  width: 16px !important;
  height: 24px !important;
}

.insta-translate-settings-wrap{
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px;
}

  /* Scroll Bar */
